@import "./bootstrap-css/index.css";

:root {
    /* COLORS */
        --abel-blue: #1A97FF;
        --abel-blue-hover: #167BCE;
        --curus-green: #00b4b4;
        --curus-green-dark: #00A2A2;
        --dark-blue: #274775;
        --dark-gray: #CCCCCC;
        --gray: #C1C1C1;
        --gray-hover: #C7C7C7;
        --green: #68E010;
        --green-hover: #52B20B;
        --light-black: rgba(0, 0, 0, .10);
        --light-gray: #FAFAFA;
        --warning: #FF6262;
        --warning-hover: #E04F4F;
        --white: #FFFFFF;


        --apple: #909090;
        --black: #545454;
        --blue-abel: #1A97FF;
        --blue-astronaut: #274775;
        --blue-astronaut-light: #4068A2;
        --blue-denim: #106BB7;
        --blue-frozen: #49B2CB;
        --blue-gray: #BFCCDE;
        --blue-light: #00C8FF;
        --blue-royal: #3569DE;
        --blue-steelblue: #3F72BC;
        --chat-gray: #F7F7F7;
        --facebook: #4469B0;
        --google: #D34836;
        --gray-ea: #EAEAEA;
        --gray-dark: #E8E8E8;
        --gray-light: #EFEFF2;
        --gray-silver: #C1C1C1;
        --green-lima: #7CE316;
        --green-lima-dark: #68E010;
        --green-success: #1FC92C;
        --orange: #FD9326;
        --orange-selectiveyellow: #FFB100;
        --purple: #9F20EF;
        --red: #DF3838;
        --red-bittersweet: #FF6262;
        --red-wellread: #B73535;
        --turquoise-dark: #1CD1CA;
        --yellow-gold: #F0A600;
        --yellow-gold-dark: #d69400;
        --yellow-medium: #FFC400;
        --white-concrete: #F2F2F2;
        --white-concrete-light: #F7F7F7;
        --white: #FFFFFF;

        --black-hover: var(--blue-steelblue);
        --blue-abel-hover: var(--blue-denim);
        --blue-astronaut-hover: var(--blue-steelblue);
        --curus-green-hover: var(--curus-green-dark);
        --green-lima-hover: var(--green-lima-dark);
        --orange-hover: #fdd241;
        --red-bittersweet-hover: var(--red-wellread);
        --yellow-gold-hover: var(--yellow-gold-dark);
        --white-concrete-hover: var(--gray-silver);
        --white-hover: var(--white-concrete);

        --link-hover: var(--blue-astronaut);

        --box-shadow-color: #B5B5B529;

        --green-success-2: #6EB71B;

    /* GRADIENTS */
      --gradient1: linear-gradient(to top right, #26C2DE, #1A98FF);

    /* BORDERS */
        --border-normal: solid 2px var(--white-concrete);
        --border-selected: solid 5px var(--abel-blue);

    /* HEART RATE ZONES */
        --heart-rate-zone-1: #B076FC;
        --heart-rate-zone-2: #1A98FF;
        --heart-rate-zone-3: #92CD4F;
        --heart-rate-zone-4: #FD9326;
        --heart-rate-zone-5: #FD6E6E;

    /* FONT FAMILY */
        --font-family-black: "Branding-Black";
        --font-family-black-italic: "Branding-BlackItalic";
        --font-family-bold: "Branding-Bold";
        --font-family-bold-italic: "Branding-BoldItalic";
        --font-family-light: "Branding-Light";
        --font-family-light-italic: "Branding-LightItalic";
        --font-family-medium: "Branding-Medium";
        --font-family-medium-italic: "Branding-MediumItalic";
        --font-family-semibold: "Branding-Semibold";
        --font-family-semibold-italic: "Branding-SemiboldItalic";
        --font-family-semilight: "Branding-Semilight";
        --font-family-semilight-italic: "Branding-SemilightItalic";
        --font-family-thin: "Branding-Thin";
        --font-family-thin-italic: "Branding-ThinItalic";

    /* FONT SIZE */
        --font-size-extra-small: 14px;
        --font-size-small: 16px;
        --font-size-medium-1: 18px;
        --font-size-medium-1half: 20px;
        --font-size-medium-2: 22px;
        --font-size-medium-3: 25px;
        --font-size-medium-4: 30px;
        --font-size-large: 35px;
        --font-size-extra-large: 45px;
        --font-size-extra-large-2: 50px;
        --font-size-extra-extra-large: 60px;
        --font-size-100: 100px;

    /* BUTTON SIZE */
        --button-height-small: 30px;
        --button-height-medium: 45px;
        --button-height-large: 60px;
        --button-height-extra-large: 65px;

    /* INPUT SIZE */
        --input-size-small: 30px;
        --input-size-small-1: 35px;
        --input-size-small-2: 40px;
        --input-size-medium: 45px;
        --input-size-large: 50px;
        --input-size-large-2: 50px;
        --input-size-extra-large: 65px;

    /* OPACITY */
        /* --opacity-low: 0.4; */
        --opacity-low: 1.0;

    /* PROFILE PHOTO */
        --profilephoto-size-small: 20px;
        --profilephoto-size-small-1: 26px;
        --profilephoto-size-small-2: 30px;
        --profilephoto-size-small-3: 35px;
        --profilephoto-size-medium: 40px;
        --profilephoto-size-large: 60px;
        --profilephoto-size-large-2: 80px;
        --profilephoto-size-large-3: 100px;
        --profilephoto-size-extra-large: 150px;

    /* SPACER SIZE */
        --spacer-size-smallest: 2.5px;
        --spacer-size-extra-extra-small: 5px;
        --spacer-size-extra-extra-small-2: 10px;
        --spacer-size-extra-small: 15px;
        --spacer-size-small: 20px;
        --spacer-size-small-1: 30px;
        --spacer-size-medium: 40px;
        --spacer-size-medium-1: 50px;
        --spacer-size-large: 60px;
        --spacer-size-large-1: 70px;
        --spacer-size-extra-large: 90px;
        --spacer-size-extra-extra-large: 95px;

    /* Toasts */
        --toastify-font-family: var(--font-family-medium);
        --toastify-color-success: var(--green-success-2);
        --toastify-icon-color-success: var(--toastify-color-success);
        /* --toastify-text-color-success: #fff; */
        --toastify-color-progress-success: var(--green-success-2);
}

.Toastify__progress-bar--success {
  background: var(--green-success-2) !important;
}

.Toastify__toast {
  font-family: var(--font-family-medium) !important;
        --spacer-size-200: 200px;
}

@media screen and (max-width: 700px) {
    :root {
        /* FONT SIZE */
            --font-size-extra-small: 14px;
            --font-size-small: 14px;
            --font-size-medium-1: 16px;
            --font-size-medium-1half: 18px;
            --font-size-medium-2: 20px;
            --font-size-medium-3: 22px;
            --font-size-large: 25px;
            --font-size-extra-large: 35px;
            --font-size-extra-large-2: 45px;
            --font-size-extra-extra-large: 50px;
    }
}

@font-face {
    font-family: 'Branding-Black';
    src: local('Branding-Black'), url(./Assets/Fonts/Branding-Black.woff) format('woff');
}
@font-face {
    font-family: 'Branding-BlackItalic';
    src: local('Branding-BlackItalic'), url(./Assets/Fonts/Branding-BlackItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Bold';
    src: local('Branding-Bold'), url(./Assets/Fonts/Branding-Bold.woff) format('woff');
}
@font-face {
    font-family: 'Branding-BoldItalic';
    src: local('Branding-BoldItalic'), url(./Assets/Fonts/Branding-BoldItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Light';
    src: local('Branding-Light'), url(./Assets/Fonts/Branding-Light.woff) format('woff');
}
@font-face {
    font-family: 'Branding-LightItalic';
    src: local('Branding-LightItalic'), url(./Assets/Fonts/Branding-LightItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Medium';
    src: local('Branding-Medium'), url(./Assets/Fonts/Branding-Medium.woff) format('woff');
}
@font-face {
    font-family: 'Branding-MediumItalic';
    src: local('Branding-MediumItalic'), url(./Assets/Fonts/Branding-MediumItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Semibold';
    src: local('Branding-Semibold'), url(./Assets/Fonts/Branding-Semibold.woff) format('woff');
}
@font-face {
    font-family: 'Branding-SemiboldItalic';
    src: local('Branding-SemiboldItalic'), url(./Assets/Fonts/Branding-SemiboldItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Semilight';
    src: local('Branding-Semilight'), url(./Assets/Fonts/Branding-Semilight.woff) format('woff');
}
@font-face {
    font-family: 'Branding-SemilightItalic';
    src: local('Branding-SemilightItalic'), url(./Assets/Fonts/Branding-SemilightItalic.woff) format('woff');
}
@font-face {
    font-family: 'Branding-Thin';
    src: local('Branding-Thin'), url(./Assets/Fonts/Branding-Thin.woff) format('woff');
}
@font-face {
    font-family: 'Branding-ThinItalic';
    src: local('Branding-ThinItalic'), url(./Assets/Fonts/Branding-ThinItalic.woff) format('woff');
}

* { box-sizing: border-box; }

html {
    height: 100%;
}

body {
    color: var(--black);
    display: flex;
    font-family: var(--font-family-medium);
    min-height: 100%;
    margin: 0px;
    width: 100%;
}

#root {
    min-height: 100%;
    width: 100%;
}

pre {
  white-space: pre-wrap;
}

a {
    text-decoration: none;
}

a:focus,
video:focus {
    outline: none;
}

.clickable:hover {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 2;
}

.data-center-table table {
  border-collapse: collapse;
}

.data-center-table table,
.data-center-table th,
.data-center-table td {
  border: 1px solid #eee;
}

/* .row, */
.sticky {
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}

.DataCenterTable {
  height: 1000px !important;
}

input, textarea {
    font-family: var(--font-family-medium);
    font-size: 1rem;
    line-height: 1.25rem;
    outline: none;
}

input[type=text], textarea {   
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=number] {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin: 0; 
}

input[type=checkbox],
input[type=radio] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input[type="checkbox"]{
    border: 2px solid var(--gray-dark);
    border-radius: 3px;
}

input[type="checkbox"].checkbox-show {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  margin-right: 5px;
  height: 13px;
  width: 13px;
}

input:disabled {
    background: var(--gray-dark);
}

.modal-size-small,
.modal-size-medium,
.modal-size-large,
.modal-size-full {
    max-width: none !important;
}

.Popover {
  z-index: 2000;
}

.popover {
  z-index: 2000;
}

/* MODAL WIDTH SIZE
--modal-size-small: 35%;
--modal-size-medium: 60%;
--modal-size-large: 85%; */

@media (max-width: 450px) {
    .modal-size-small, .modal-size-medium, .modal-size-large, .modal-size-full {
        width: 93% !important;
    }
}

@media (min-width: 451px) {
    .modal-size-small, .modal-size-medium, .modal-size-large, .modal-size-full {
        width: 90% !important;
    }
}

@media (min-width: 701px) {
    .modal-size-small, .modal-size-medium, .modal-size-large, .modal-size-full {
        width: calc(0.8*87%) !important
    }
}

@media (min-width: 1126px) {
    .modal-size-small { width: 35% !important; }
    .modal-size-medium { width: 60% !important; }
    .modal-size-large { width: 85% !important; }
    .modal-size-full { width: 92.5% !important; }
}

.display-none {
    display: none !important;
}

.filter-category-container {
    display: flex;
}

.filter-categories-container {
    display: flex;
    overflow: auto;
    white-space: nowrap;
}

.filter-category {
    margin-right: 20px;
    border: 2px solid var(--blue-abel);
    padding: 5px 10px;
    border-radius: 20px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.NotificationsModal {
    align-items: flex-start;
    margin: 0;
    min-height: 0;
    position: absolute;
    right: 30px;
    top: 130px;
    width: 300px !important;
}




/* REACT TOGGLE */
.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}
  
.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle-track {
    width: 35px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #FFFFFF;
    border: 2px solid #F2F2F2;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle--checked .react-toggle-track {
    background-color: white;
    border: 2px solid #F2F2F2;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #545454;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 12px;
    background-color: #1A97FF;
}

/* INPUT RANGE */
.input-range__slider {
    background: #1A97FF;
    border: 2px solid #FFFFFF;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    /* transition: transform 0.3s ease-out, box-shadow 0.3s ease-out; */
    width: 1rem;
}

.input-range__slider:active {
    transform: scale(1.3);
}

.input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
}
  
/* .input-range__slider-container {
    transition: left 0.3s ease-out;
} */

.input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    transform: translateZ(0);
    white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--value {
    position: absolute;
    top: -1.8rem;
}

.input-range__label-container {
    left: -50%;
    position: relative;
}
.input-range__label--max .input-range__label-container {
    left: 50%;
}

.input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    /* transition: left 0.3s ease-out, width 0.3s ease-out; */
}
.input-range--disabled .input-range__track {
    background: #eeeeee;
}

.input-range__track--background {
    left: 10px;
    margin-top: -0.15rem;
    position: absolute;
    right: 10px;
    top: 50%;
}

.input-range__track--active {
    background: #1A97FF;
}

.input-range {
    height: 1rem;
    position: relative;
}


/* TIME PICKER */
.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
}

.rc-time-picker * {
  box-sizing: border-box;
}

.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
  display: none;
}

.rc-time-picker-clear-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 20px;
  width: 20px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.rc-time-picker-clear-icon:hover:after {
  color: #666;
}

.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 45px;
  cursor: text;
  font-family: var(--font-family-semibold);
  font-size: var(--font-size-medium-3);
  text-align: center;
  line-height: 1.5;
  color: var(--black);
  background-color: #fff;
  background-image: none;
  border: 2px solid var(--white-concrete);
  border-radius: 10px;
  -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}

.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
}

.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}

.rc-time-picker-panel-narrow {
  max-width: 114px;
}

.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}

.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}

.rc-time-picker-panel-input-invalid {
  border-color: red;
}

.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}

.rc-time-picker-panel-select-active {
  overflow-y: auto;
}

.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}

.rc-time-picker-panel-select:last-child {
  border-right: 0;
}

.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.rc-time-picker-panel-select li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}

li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}

li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}

li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}









/* REACT-DATETIME /*
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

 .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    width: 250px;
    padding: 4px;
    margin: 10px auto 0;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }

  .rdt > input {
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 20px;
    color: var(--blue-astronaut);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-3);
    justify-content: center;
    line-height: 1.25rem;
    margin: 0 auto;
    padding: 5px 10px;
    text-align: center;
    height: var(--input-size-medium);
    outline: none;
    width: 300px;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }


























  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    width: 100%;
  }
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }