circle {
  stroke: #1A97FF;
  stroke-width: 18;
  fill: transparent;
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 0.5s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}